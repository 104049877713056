@import '../../../scss/vars.scss';

.radioButtonWrapper {
    width: 100%;
}
.radioButtonWrapper > div {
    width: 100%;
}

.radioButtonWrapper fieldset {
    width: 100%;
}
