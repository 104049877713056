@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.button {
    cursor: pointer;
    @include remove-button-styles;
    @include font--button;
    font-size: 1rem;
    text-align: center;
    transition: 0.3s all cubic-bezier(0.33, 0, 0.2, 1);
    padding: 1rem $gutter;

    &[disabled] {
        cursor: not-allowed;
    }

    &:focus {
        outline: 1px solid $color--quill;
    }

    &--gray {
        background-color: $button--default;
        color: $color--black;
    }

    &--red {
        background-color: $button--red;
        color: $color--white;
    }

    &--white {
        background-color: $color--white;
        color: $color--black;
    }

    &--blue {
        background-color: $button--blue;
        color: $color--black;
    }

    &--green {
        background-color: $button--green;
        color: $color--black;
    }

    &--gray,
    &--green,
    &--red,
    &--white,
    &--blue {

        &:hover:not([disabled]),
        &:focus:not([disabled]) {
            outline: none;
            background-color: $color--abbey-1;
            color: $color--white;
        }
    }

    &--icon {
        display: block;
        background: transparent;
        padding: 0;
    }

    &__icon {
        display: inline-block;

        &--small {
            // NOTE: 44px square is the minimum OK hit area. Buttons with smaller icons are padded to meet this requirement ;
            padding: 10px;
        }
    }


}
