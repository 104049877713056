@import './vars.scss';
@import './breakpoints.scss';
@import '@in/component-library/src/styles/variables.scss';
@import './fonts.scss';

@import "font-icons";
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    font-family: "InnovationNorway", $font-family-sans-serif;
    color: $body-font-color;
    min-width: fit-content;
}

.mouse-user * {
    outline: none;
}

.visually-hidden {
    display: none;
}

.hidden-input {
    visibility: hidden;
    &:focus+.preview {
        border: 1px solid red;
    }
}

.form_label {
    color: $in-black;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    margin: 5px 0;
    padding-left: 0;
    &-helper {
        width: 25px;
        height: 25px;
        border-radius: 25px;
        display: inline-block;
        text-align: center;
        position: relative;
        top: 1px;
        border: solid 1px $primary-color;
        background: $white;
        font-weight: normal;
        transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s;
        cursor: pointer;
        margin: 3px;
        margin-bottom: 6px;
        &:not(.open):hover {
            color: $primary-color;
        }
        &.open {
            background-color: $primary-color;
            color: $white;
        }
        &-text {
            color: $oil;
            font-size: 1rem;
            border: none;
            background-color: $callout-bg-color;
            padding: 1rem;
            margin-bottom: 1rem;
            display: inline-block;
        }
    }
}

label.no-click-label {
    cursor: default;
}

.optional-label {
    color: $gray6;
    font-style: italic;
}

.preview-value {
    font-style: italic;
    padding-left: 15px;
}

.error-text {
    color: $alert-color;
    font-style: italic;
    padding: 8px;
}

.error-text-consideration {
    color: $alert-color;
    font-style: italic;
    padding: 8px;
    display: flex;
    justify-content: flex-end;
}

.error-message {
    display: block;
    border: 1px solid $alert-color;
    text-align: center;
    color: $alert-color;
    padding: 1rem;
    margin: 1rem 0;
}

.form-input-error {
    display: block;
    font-size: 16px;
    color: $alert-color;
    background: none;
    font-style: normal;
    @media screen and (max-width: $break-at-sm) {
        padding-top: 1.2rem;
    }
}

table {
    width: 100%;
    border: none;
    tr {
        page-break-inside: avoid;
    }
}

.pageLayoutRegularPage {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 0rem;
    padding: 0 1rem;

    @include breakpoint-md {
        grid-template-columns: 4fr 1fr;
    }
    @include breakpoint-lg {
        grid-template-columns: 4fr 1fr;
    }

}
