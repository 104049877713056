@import '../../../scss/vars.scss';
.input-group-checkbox {
    height: 20px;
    width: 20px;
    margin: 0;
    padding-top: 10px;
    padding-right: 20px;
    max-width: 700px;
    vertical-align: middle;
    transition: all 0.15s linear;
    min-width: 20px;

    &:focus {
        transition: all 0.15s linear;
    }
    &__label {
        padding-left: 20px;
    }
    &__input-label-wrapper {
        min-height: 30px;
        display: flex;
        vertical-align: middle;
        white-space: nowrap;
    }
}

.input-group-checkbox-cl.tabbed {
    margin-left: 30px;
}

.oneline {
    display: inline-flex;
    border-top: 0px;
}

.input-group-checkbox-helptext {
    margin-left: 30px;
    width: 100% !important;
}

.input-group-checkbox-helptext.tabbed {
    margin-left: 30px;
}

.oneline > fieldset {
    margin-bottom: 0px !important;
}

.innovationCriteriaInvisible {
    display: none;
}

.errorText {
    color: #d91f12;
    margin-bottom: 20px;
}

.errorText.tabbed {
    margin-left: 2rem;
}

.errorIcon {
    height: 16px;
    width: 16px;
}

.orange {
    background-color: orange;
}

.input-group-checkbox-cl .oneline {
    width: 100%;
}

.input-group-checkbox-cl fieldset {
    width: 100%;
}
