$application--min-width: 180px;
$application--max-width: 1220px;
$panel--margin-bottom: 20px;
$red: #f05a4e;
$green: #82dbb8;
$green2: #78ceac;
$salmon: #ffd2bb;
$yellow: #feeead;
$yellow-highlight: #fef3cb;
$highlight-link-underline-color: #ecd16d;
$gray1: #f2f2f2;
$gray2: #f9f9f9;
$gray3: #dbdbdb;
$gray4: #868080;
$gray5: #4d4d4d;
$gray6: #5f5f5f;
$in-black: #363636;
$callout-bg-color: $gray2;
$callout-color: $salmon;
$link-underline-color: $gray3;
// ****************************************************************************
// Copied from MinSide /sass/foundation/components/_settings.scss
// ****************************************************************************
$font-family-sans-serif: "Circular-Book",
AdobeBlank;
$font-family-bold: "Circular-Medium",
AdobeBlank;
$primary-color: $green2;
$primary-color-hover: #48bd8f;
$secondary-color: $gray3;
$alert-color: $red;
$alert-color-hover: #ed3425;
$success-color: $green;
$input-focus-border-color: #0057b8;
$body-bg: $gray1;
$body-font-color: $in-black;
// *****************************************************************************
// Copied from MinSide /sass/foundation/components/_global.scss
// ******************************************************************************
$white: #FFFFFF !default;
$ghost: #FAFAFA !default;
$snow: #F9F9F9 !default;
$vapor: #F6F6F6 !default;
$white-smoke: #F5F5F5 !default;
$silver: #EFEFEF !default;
$smoke: #EEEEEE !default;
$gainsboro: #DDDDDD !default;
$iron: #CCCCCC !default;
$base: #AAAAAA !default;
$aluminum: #999999 !default;
$jumbo: #888888 !default;
$monsoon: #777777 !default;
$steel: #666666 !default;
$charcoal: #555555 !default;
$tuatara: #444444 !default;
$oil: #333333 !default;
$jet: #222222 !default;
//******************************************************************************
//  From innovasjonnorge.no new styleguide
//******************************************************************************
$text-size-base: 16px;
$line-height: 2.125rem;
$line-height--lg: 2.625rem;
$text-size: 1rem;
$text-size--sm: 0.8rem;
$text-size--lg: 1.375rem;
$text-size--hg: 1.75rem;
$font-weight-sm: 300;
$font-weight: 500;
$font-weight--lg: 700;
$font-family-heading: 'Circular Black',
Helvetica,
Arial;
$font-family-intro: 'Circular Medium',
Helvetica,
Arial;
$font-family-text: 'Circular Book',
Helvetica,
Arial;
$font-family-text-italic: 'Circular Book',
Helvetica,
Arial;
$font-family-bold: 'Circular Bold',
Helvetica,
Arial;
$font-family-monospaced: 'Relative',
Courier;

$text-size--small: 0.875rem; // 14px
$text-size--normal: 1rem; // 16px
$text-size--big: 1.188rem; // 19px
$text-size--bigger: 1.25rem; // 20px
$text-size--large: 1.375rem; // 22px
$text-size--larger: 2.06rem; // 33px



$gutter: 2rem;
$gutter--lg: 5.5rem;

$gutter--smallest: 0.6rem;
$gutter--small: 1rem; // 16px
$gutter--medium: 2.5rem; //40px
$gutter--large: 4rem; // 64px
$gutter--huge: 5.5rem; // 88px
$gutter--block: 6.25rem; // 100px

//Break points and widths correspond with style guide
$page-width--medium: 64rem + $gutter + $gutter; //1024px + gutters
$page-width--large: 75rem + $gutter + $gutter; //1200px + gutters


//Break points and widths correspond with style guide
$page-width--sm: 25rem+$gutter+$gutter; //400px + gutters
$page-width--md: 48rem+$gutter+$gutter; //768px + gutters
$page-width--lg: 75rem+$gutter+$gutter; //1200px + gutters
$page-width--legacy: calc(1024px); //960px + gutters
$break-at-sm: $page-width--sm;
$break-at-md: $page-width--md;
$break-at-lg: $page-width--lg;
//Colors correspond with style guide
//https://company-139306.frontify.com/document/247152#/foundation/colors
//Main colors
$color--black: #000;
$color--white: #fff;
$color--quill: #d9d9d6;
$color--swirl: #d7d2cb;
$color--tower: #b1b3b3;
$color--abbey-1: #53565a;
$color--bronco: #aca39a;
$color--pine: #6e6259;
$color--romantic: #ffd2bb;
$color--regent: #a4dbe8;
$color--botticelli: #c6d6e3;
$color--mandy: #e03e52;
$color--riptide: #7ae1bf;
$color--portica: #f6eb61;
$color--pomegranate: #ed3426;
//colors that are not part of design system and instead added willy-nilly to random pages and components
$color--yellow: #fdfbdf;
$color--shortcut-grey: #91979c;
//Extra colors
$color--purple: #753bbd;
$color--red: #af272f;
$color--turbo: #fce300;
$color--lima: #0057b8;
$color--abbey: #75ba20;
//Background colors
$background--quill: rgba($color--quill, 0.3);
$background--romantic: rgba($color--romantic, 0.3);
$background--regent: rgba($color--regent, 0.3);
$background--botticelli: rgba($color--botticelli, 0.5);
//Button colors
$button--default: $color--quill;
$button--blue: $color--regent;
$button--red: $color--mandy;
$button--green: $color--riptide;
//Color modifiers
$color--option1: #fff4ee;
$color--option2: #fdfbdf;
$color--option3: #c6d6e3;
// ****************************************************************************
// ****************************************************************************

$color--grey-30: #f8f7f7;
$color--grey-50: #f3f1ef;
$color--grey-70: #e3e4e4;
$color--grey-100: #d9d9d6;
$color--grey-200: #b1b3b3;
$color--grey-400: #7a7a7a;
$color--grey-600: #53565a;
$color--grey-700: #3a3c40;
