@import '../../scss/vars.scss';

.content-area {
    color: $gray4;
    display: block;
    margin-bottom: 1rem;
    min-width: 250px;
    max-width: 100%;
    position: relative;
    margin: auto;
    background: $gray2;
    padding:40px;
    overflow-x: auto;
}

.form-content {
    color: #868080;
    margin-top: 4px;
}

.form-field label {
    margin-top: 8px;
    padding-right: 15px;
    width: 250px;
}

.form-field input {
    max-width: 530px;
    min-width: 530px;
    width: 530px;
    color: #868080;
    padding: 4px;
    font-size: 14px;
    margin-top: 5px;
    border: solid 1px #868080;
    -webkit-transition: all .15s linear;
    transition: all .15s linear;
}

.form-field {
    display: flex;
    flex-direction: row;
}

.risk-classes input {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
}

.form-field .checkbox {
    width: auto;
    max-width: auto;
    min-width: auto;
}

.risk-classes {
    margin-left: 250px;
}

.left-m-20 {
    margin-left: 20px;
}

.left-m-10 {
    margin-left: 10px;
}

.top-m-10 {
    margin-top: 10px;
}

.select {
    height: 27px;
    padding: 4px;
    margin-top: 5px;
}

.add-button {
    margin-left: 272px;
}