@import '../../../scss/vars.scss';
@import '@in/component-library/src/styles/variables.scss';
@import '../../../scss/breakpoints.scss';

.nbreak {
    white-space: nowrap;
    align-items: baseline;
}

.summary.section {
    width: 100%;
    display: flex;
    padding: 0px;
    border-bottom: 1px solid Black;
    margin-bottom: 30px;
}

.summary.header {
    width: 100%;
    font-size: 24px;
    color: black;
    white-space: normal;
}

.summary-item {
    display: flex;
    padding: 0px;
    margin-left: auto;
    margin-bottom: 20px;
}

.summary-item.last {
    margin-bottom: 40px;
}

.summary-item-header {
    width: 100%;
    font-size: 22px;
    color: black;
    white-space: normal;
}

.summary-item-value {
    width: 100%;
    font-size: 16px;
    color: black;
    white-space: normal;
    word-break: break-word;
}

.summary-item-button.wrapper {
    align-self: top;
    margin-left: auto;
    margin-top: 6px;
    right: -4px;
    position: relative;
}

.summary.button {
    padding: 0px;
    margin-left: auto;
}

.summary.button:focus {
    background-color: $in--blue-50;
    outline: 0;
}
.summary.button:active {
    background-color: $in--blue-100;
}

.summary.button.wrapper:focus-within {
    background-color: $in--blue-50;
    outline: 1px solid #000;
}

.summary.button.wrapper:active {
    background-color: $in--blue-100;
}

.summary.button.open {
    transform: scale(1.1) rotate(45deg);
}

.summary.button.wrapper {
    align-self: flex-end;
}

.summary.button.wrapper:active {
    outline: 1px solid #000;
}


.svg-inline--fa {
    vertical-align: -0.225em !important;
}
