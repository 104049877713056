@mixin remove-list-styles {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin remove-link-styles {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
        outline: initial;
        color: initial;
        text-decoration: none;
    }
}

@mixin remove-heading-styles {
    line-height: initial;
    color: inherit;
    margin: 0;
}

@mixin remove-input-styles {
    margin: 0;
    border: 0;
    outline: 0;
    padding: 0;
    -webkit-appearance: none;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
}

@mixin remove-button-styles {
    font-size: 1rem;
    border: 0;
    border-radius: 0;
    overflow: hidden;
    float: initial;
    height: auto;
    display: inline-block;
    text-align: left;
    outline-offset: 0;
    text-decoration: none;
    transform: translateZ(0);
    background-color: transparent;
    padding: 0;
    margin: 0;
    letter-spacing: inherit;
    line-height: inherit;
}

@mixin font--bold {
    font-family: 'Circular Bold', Helvetica, Arial;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
}

@mixin font--heading {
    font-family: 'Circular Black', Helvetica, Arial;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
}

@mixin font--button {
    font-family: 'Circular Medium', Helvetica, Arial;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
}

@mixin font--uppercase {
    @include font--text;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
}

@mixin font--text($weight: normal, $style: normal) {
    font-family: 'Circular Book', Helvetica, Arial;
    font-stretch: normal;
    font-style: $style;
    font-weight: $font-weight-sm;
    font-size: $text-size--lg;
}

@mixin link-underlined--inverted {
    @include remove-link-styles;
    border-bottom: 1px solid $color--black;
    transition: 0.3s all cubic-bezier(0.33, 0, 0.2, 1);

    &:focus,
    &:hover {
        color: $color--abbey-1;
        border-bottom: 1px solid transparent;
    }
}

@mixin link-underlined {
    @include remove-link-styles;
    border-bottom: 1px solid transparent;
    transition: 0.3s all cubic-bezier(0.33, 0, 0.2, 1);

    &:focus,
    &:hover {
        border-bottom: 1px solid $color--black;
    }
}

@mixin link-colorline($fromColor: $color--black, $toColor: transparent) {
    @include remove-link-styles;
    border-bottom: 4px solid $fromColor;
    transition: 0.3s all cubic-bezier(0.33, 0, 0.2, 1);

    &:focus,
    &:hover {
        color: inherit;
        border-bottom: 4px solid $toColor;
    }
}

@mixin arrow--after {
    &::after {
        content: '➝';
        font-size: 1em;
        margin-left: 0.25em;
    }
}

@mixin arrow--before($size: 1.8em) {
    position: relative;

    &::before {
        content: '➝';
        font-size: $size;
        position: absolute;
        left: -1.2em;
        display: inline-block; //Firefix (62.0.3) has a problem rendering this consistenly after repeated refreshes -moz-transform: translate(0, 0.05em) !important;
    }
}

@mixin visually-hidden {
    position: absolute;
    width: 0;
    height: 0;
    left: -999em;
    overflow: hidden;
}
