@import "../../../scss/vars.scss";

.form-label-generated {
    align-self: center; 
    width: 50%;
    color: $in-black;
}

.showEffectOfSubsidy {
    color: $in-black;
    padding: 20px;
    margin: 7px 7px 7px 0px;
    width: 9em;
    text-align: right;
}

.flexrow-center {
    align-items:center;
}

.descriptionWrapper {
    white-space: normal;
    margin-top: 12px;
}

.fieldWrapper.generated {
    font-size: 20px;
    font-family: "InnovationNorway",Helvetica,Arial,sans-serif;
    font-weight: 400;
}