@import '../../scss/vars.scss';
@import '@in/component-library/src/styles/variables.scss';
@import '../../scss/breakpoints.scss';

.chart-container {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
}

.pieChart {
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
}
.alignLeft {
    margin-left: 0;
    margin-right: auto;
}

.recharts-legend-wrapper ul > li {
    width: 100%;
}

.legendList {
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
}

.legendList .leadText {
    color: black;
    margin-left:0;
    margin-right: auto;
}
.legendList .leadText.last {
    padding-bottom:1rem;
}


.legendList .square {
    margin-top:0;
    margin-bottom: auto;
    margin-right: 0.5rem;
    min-width: 1rem;
    width:1rem;
    min-height: 1rem;
    height:1rem;
}
.squareWrapper {
    width:fit-content;
    vertical-align: top;
    padding-top: 0.5rem;
}
