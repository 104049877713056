@import '../../../scss/vars.scss';

.dropzoneCl.info {
    padding: 10px;
    border: 1px solid #abbccf;
    white-space: normal;
    font-size: 12px;
    top: -30px;
    position: relative;
}

.fileRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.dropzoneLoading {
    min-height: 200px;
}
