@import '../../scss/vars.scss';
.fontawesome {
    margin: auto 2px;
    padding: 0 3px;
    height: inherit;
    &.alert {
        color: $alert-color;
    }
    &.success {
        color: $success-color;
    }
}
