.icon {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin: 0 5px;

  &--tiny {
    width: 10px;
    height: 10px;
  }

  &--small {
    width: 15px;
    height: 15px;
  }

  &--medium {
    width: 20px;
    height: 20px;
  }

  &--large {
    width: 30px;
    height: 30px;
  }

  &--xlarge {
    width: 45px;
    height: 45px;
  }
}
