@import '../../scss/vars.scss';
@import '../../scss/breakpoints.scss';
@import '@in/component-library/src/styles/variables.scss';

.applist.infoBoxWrapper {
    font-size: 16px;
    padding: 0px;
    padding: 1px 30px 20px 30px;
}

.applist.closeWrapper {
    font-size: 16px;
    display: flex;
    background: rgba(164, 219, 232, 0.3);
    padding: 4px;
    margin-bottom: 20px;
}

.messageClosed {
    display: none !important;
}

.applist.ingress {
    font-size: 28px;
}

.applist.normal {
    font-size: 20px;
}

.applist.wider {
    flex: 100vw;
}

.applist.wide {
    flex: 77vw;
}

.applist.widecentered {
    justify-content: space-between !important;
}

.applist.infoBoxHeader {
    font-size: 24px;
    font-weight: bold;
}

.infoBoxList {
    list-style-type: '- ';
    font-weight: bold;
    padding-left: 10px;
    margin-top: 0px;
}

.infoBoxBold {
    font-weight: bold;
}

.noBottomMargin {
    margin-bottom: 0px;
}

.applist.buttonWrapper {
    width: fit-content;
    margin: auto;
    padding-top: 3rem;
    @include breakpoint-md {
        margin: 5rem 0 0 1rem;
    }
}

.applist.headerButtonWrapper {
    padding: 0px;
    border: 0px;
    margin: 0px;
}

.applist table {
    border: 1px solid grey;
    border-collapse: separate;
    border-spacing: 2px;
    border: 0;
}

.applist tr td {
    padding: 1em;
    border: 0;
    margin: 0;
    border-bottom: 1px solid #ccc;
}

.applist.head {
    background: #ddd;
    outline-color: #eee;
}

.applist.button {
    border: none;
}

.applist.flextable {
    flex-direction: row;
    width: 100%;
}

.applist.flexrow {
    display: flex;
    border-bottom: 1px solid #ddd;
    min-height: 60px;
    width: 100%;
    padding-left: 10px;
}

.applist.flexrow.header {
    background-color: #ddd;
    font-size: 16px;
    display: flex;
    min-height: 60px;
    width: 100%;
    padding-left: 10px;
    font-weight: 700;
}

.applist.flexrow.header .columnName {
    outline-style: inherit;
    white-space: nowrap;
    border-bottom: 0px;
}

.applist.flexrow.header .columnName:hover,
.applist.flexrow.header .columnName:focus,
.applist.flexrow.header .columnName.selected {
    border-bottom: 1px solid #000;
    margin-bottom: -1px;
}

.applist.flexrow.header .noOutline,
.applist.flexrow.header .noOutline:hover,
.applist.flexrow.header .noOutline:focus {
    border-bottom: none;
    margin-bottom: 1px;
}

.applist.flexrow.header .applist.flexitem {
    flex-wrap: nowrap;
}

.applist.flexrow.header .applist.flexitem.selected {
    flex-wrap: nowrap;
}

.applist.flexitem {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;
    min-height: 60px;
    padding: 4px;
}

.flexitem .fontawesome {
    height: auto;
}

#closeButton {
    position: absolute;
    top: 0;
    right: 0;
}

.applist.closeIcon {
    position: relative;
    cursor: pointer;
    height: 25px;
}

.applist.textFiltersHeader {
    width: 100%;
    font-size: 14px;
    margin-left: 10px;
}

.applist.textFilters {
    display: flex;
    width: 100%;
    font-size: 22px;
    padding: 4px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    cursor: pointer;
}

.applist.filter {
    padding: 5px;
    margin: 0px 20px 0px 0px;
    outline: none;
    border: 3px solid #fff;
    color: #000;
}
.applist.filter:focus,
.applist.filter:hover {
    text-decoration: underline;
}

.applist.filter.selected {
    font-weight: 700;
    color: #000;
    padding: 5px;
    margin: 0px 20px 0px 0px;
    outline-color: #eee;
}
.applist.filter.selected:focus,
.applist.filter.selected:hover {
    text-decoration: underline;
}

.applist.header .flexitem {
    cursor: pointer;
}

.applist.flexitem.noOutline {
    cursor: default;
}

.radioButtonWrapper {
    margin-top: 40px;
}

.radioButtonWrapper .fieldset {
    margin-bottom: 0px;
}

.fileDownload {
    cursor: pointer;
}

.downloadItem {
    flex-direction: column;
    align-items: center;
    display: flex;
    outline: none;
}

.downloadItem:hover {
    border-bottom: 1px solid #000;
    margin-bottom: -1px;
}

.downloadItem:focus {
    border-bottom: 1px solid #000;
    margin-bottom: -1px;
}

.column {
    color: #777;
}

.columnSelected {
    color: #000;
}
