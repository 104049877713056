@import '../../../scss/vars.scss';

.input.telephoneNumber {
    width: 200px;
}

.select {
    padding: 4px;
    color: $gray4;
    font-size: 14px;
    margin-top: 5px;
    border: solid 1px $gray4;
    transition: all 0.15s linear;
}
.select.readonly {
    color: #bbb !important;
}

.select:focus {
    color: $color--black;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.15s linear;
}
