@import '../../../scss/vars.scss';

.input.telephoneNumber {
    width: 200px;
}

.nobreak {
    display: flex;
    align-items: baseline;
}

.nbreak {
    white-space: nowrap;
    align-items: baseline;
}

.suffix {
    padding-left: 5px;
}

.input-text-helptext {
    margin-left: 0px;
    max-width: 400px;
}

.iconIE11FlexCorrect {
    width: 30px !important;
}

.OverrideCLLastChildBorder {
    margin-bottom: 0.5rem;
}
