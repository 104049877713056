@font-face {
    font-family: 'Circular-Book';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: local('Circular-Book'), url('../assets/fonts/lineto-circular-book.woff') format('woff'), url('../assets/fonts/lineto-circular-book.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/lineto-circular-book.ttf') format('truetype'),
        url('../assets/fonts/lineto-circular-book.svg#lineto-circular-book') format('svg');
}

@font-face {
    font-family: 'Circular-Medium';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: local('Circular-Medium'), url('../assets/fonts/lineto-circular-medium.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/lineto-circular-medium.woff') format('woff'),
        url('../assets/fonts/lineto-circular-medium.ttf') format('truetype'), url('../assets/fonts/lineto-circular-medium.svg#lineto-circular-medium') format('svg');
}

@font-face {
    font-family: 'icomoon';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: local('icomoon'), url('../assets/fonts/icomoon.eot?#iefix-p7u2ou') format('embedded-opentype'), url('../assets/fonts/icomoon.ttf?-p7u2ou') format('truetype'), url('../assets/fonts/icomoon.woff?-p7u2ou') format('woff'),
        url('../assets/fonts/icomoon.svg?-p7u2ou#icomoon') format('svg');
}

//Normal
@font-face {
    font-family: 'InnovationNorway';
    src: url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Regular-Web.woff2') format('woff2'), url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Regular-Web.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

// Italic
@font-face {
    font-family: 'InnovationNorway';
    src: url('https://https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Bold-Web.woff2') format('woff2'),
        url('https://https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Bold-Web.woff') format('woff');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

// Medium
@font-face {
    font-family: 'InnovationNorway';
    src: url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Semibold-Web.woff2') format('woff2'), url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Semibold-Web.woff') format('woff');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

// Medium and italic
@font-face {
    font-family: 'InnovationNorway';
    src: url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-SemiboldIt-Web.woff2') format('woff2'), url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-SemiboldIt-Web.woff') format('woff');
    font-style: italic;
    font-weight: 600;
    font-display: swap;
}

// Bold
@font-face {
    font-family: 'InnovationNorway';
    src: url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Bold-Web.woff2') format('woff2'), url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-Bold-Web.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

// Bold and italic
@font-face {
    font-family: 'InnovationNorway';
    src: url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-BoldIt-Web.woff2') format('woff2'), url('https://component-library-cdn-dev.innovasjonnorge.no/fonts/InnovationNorway-BoldIt-Web.woff') format('woff');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}
