@import '../../scss/vars.scss';

.organisationOverview {
    color: $gray4;
    display: block;
    margin-bottom: 1rem;
    min-width: 250px;
    max-width: 100%;
    position: relative;
    margin: auto;
    background: $gray2;
    padding:40px;
    overflow-x: auto;
}

.organisationError {
    padding: 10px;
    font-family: "Circular-Book", AdobeBlank;
    font-size: 20px;
    display: flex;
    flex-direction: row;
}

.organisationError .iconButton {
    color: black;
    width: 22px;
}

.organisationErrorTxt {
    display: flex;
    flex-grow: 1;
}

.organisationOverview .table {
    input {
        font-size: 16px;
        font-family: inherit;
    };
}

.organisationOverview .table .group-row .text-column {
    input {
        width: 100%;
        max-width: 300px;
    };
}

.organisationOverview .table .group-row {
    height: 30px;
}

.organisationOverview .table .header-element {
    text-align: left;
}

.organisationOverview .table .group-row .index-column {
    width: 50px;
    text-align: center;
}

.organisationOverview .table .group-row .text-column {
    min-width: 205px;
}

.organisationOverview .table .group-row .checkbox-column {
    text-align: center;
    max-width: 150px;
}

.iconButton:hover {
    background: $gray4;
    color: white;
}

.iconButton {
    cursor: pointer;
    border: solid transparent 1px;
    font-size: 16px;
    width: 100px;
    padding: 2px;
    font-family: "Circular-Book", AdobeBlank;
    font-weight: 800;
    color: rgb(134, 128, 128);
}

.small-iconButton {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    display: inline-block;
    padding: 4px 2px 2px 2px;
}

.authGroup {
    color: black;
}

.deletedAuthGroup {
    color: red;
    text-decoration: line-through;
}
