.footerHeader {
  font-weight:600;
}

.pageContent {
  display: block;
  margin-bottom: 1rem;
  min-width: auto;
  max-width: 1440px;
  position: relative;
  margin: auto;
}

.siteBody {
  background-color: white;
  display: block;
  min-width: auto;
  max-width: 1264px;
  min-height: 340px;
  margin: auto;
  padding: 0 0 1rem;
}

.visibleLink {
    color:black;
}

.visibleLink a:link {
  color: black;
}

.visibleLink a:visited {
  color: black;
}

.visibleLink a:hover {
  color: black;
}

.visibleLink a:active {
  color: black;
}
.noUnderline, .noUnderline:active, .noUnderline:hover, .noUnderline:focus {
  border-bottom: none !important;
}
