.input.telephoneNumber {
    width: 200px;
}

.date-width {
    width: 6em !important;
}

// Fix for the close button
.react-datepicker__close-icon:after {
    padding-bottom: 3px !important;
    line-height: 0.1rem !important;
}

// Fix for shrinking the date, so we can have 3 adjacent dates, without forcing a wider space in the page
.react-datepicker__input-container input {
    width: 100%;
}
