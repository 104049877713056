@import "../../../scss/vars.scss";
.input-checkbox {
    display:flex;
    height:20px;
    width:20px;
    margin:0;
    padding-top:10px;
    vertical-align: middle;
    transition: all .15s linear;
    
    &:focus {
        transition: all .15s linear;
    }
    &__label {
        padding-left: 20px;
    }
    &__input-label-wrapper {
        min-height:30px;
        display: flex;
        vertical-align: middle;
        white-space:nowrap;
    }
}

.form-label {
    padding-right:15px;
}

fieldset.A_2_13_Checkbox {
    .form-field__error {
        display:none;
    }
}