@import '@in/component-library/src/styles/variables.scss';

// Konvertering:

// New
// $breakpoint-sm: 24em; // 384px
// $breakpoint-md: 48em; // 768px
// $breakpoint-lg: 64em; // 1024px
// $breakpoint-xl: 75em; // 1200px

// Old
// $layout-breakpoint-sm: 768px;
// $layout-breakpoint-md: 1024px;
// $layout-breakpoint-lg: 1200px;
// $layout-breakpoint-xl: 1980px;

@mixin breakpoint-xs {
    @content;
}
@mixin breakpoint-xs-max {
    @media screen and (max-width: $breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-sm {
    @media screen and (min-width: $layout-breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-sm-max {
    @media screen and (max-width: $layout-breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-md {
    @media screen and (min-width: $layout-breakpoint-md) {
        @content;
    }
}
@mixin breakpoint-md-max {
  @media screen and (max-width: $layout-breakpoint-lg) {
      @content;
  }
}

@mixin breakpoint-lg {
    @media screen and (min-width: $layout-breakpoint-lg) {
        @content;
    }
}
@mixin breakpoint-lg-max {
  @media screen and (max-width: $layout-breakpoint-xl) {
      @content;
  }
}
