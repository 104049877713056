@import '../../../scss/vars.scss';

.input.telephoneNumber {
    width: 200px;
}

.nobreak {
    display: flex;
    align-items: baseline;
    // background: orange;
}

.suffix {
    padding-left: 5px;
}

.input:focus {
    color: $color--black;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.15s linear;
}

.input {
    color: $gray4;
    width: 100%;
    padding: 4px;
    font-size: 14px;
    max-width: 850px;
    margin-top: 5px;
    border: solid 5px green;

    border: solid 1px $gray4;
    transition: all 0.15s linear;
    textarea {
        margin-top: 5px;
        min-height: 3rem;
        height: 500px;
        min-width: 1000px;
        border: solid 5px red;
    }
}

.form-textarea-label {
    padding-bottom: 5px;
    display: block;
}

.warningWrapper {
    color: rgb(255, 130, 130);
    font-weight: 300;
    width: 100%;
    margin-left: 5px;
}

.textareaCaption {
    width: 20rem;
    font-size: 11px;
    margin-top: 3px;
}

.counter {
    transition: all 0.7s;
    background: white;
}
.alert {
    background: #f9e3b3;
    transition: all 0.7s;
    color: $in-black;
}
.textareaInfo {
    padding: 0px 0px 10px 0px;
    white-space: normal;
    font-size: 12px;
    max-width: 500px;
}

.textareaInfoCl {
    padding: 0px 0px 10px 0px;
    white-space: normal;
    font-size: 20px;
}
