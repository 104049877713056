@import '@in/component-library/src/styles/variables.scss';

.isa_info, .isa_success, .isa_warning, .isa_error {
    margin-right: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    }
    .isa_info {
        color: #00529B;
        background-color: #BDE5F8;
        border:1px solid #bbb;
    }
    .isa_success {
        color: #4F8A10;
        background-color: #DFF2BF;
        border:1px solid #bbb;
    }
    .isa_warning {
        color: #9F6000;
        background-color: #FEEFB3;
        border:1px solid #bbb;
    }
    .isa_error {
        color: #D8000C;
        background-color: #FFD2D2;
        border:1px solid #bbb;
    }
    .isa_info i, .isa_success i, .isa_warning i, .isa_error i {
        margin:5px 22px;
        font-size:1.1em;
    }
    .isa_msg {
        display: flex;
        flex: 1 1 0;
    }
    .isa_close {
        cursor: pointer;
    }
    
    .messageWrapperTop {
        min-width: 400px;
        margin-left: auto;
        margin-right: auto;
        max-width:80%;
    }

    .successMessageWrapper {
        width: 100%;
        height:0px;
        position: relative;
        z-index: 100;
        left: -5px;
        top: -2rem;
    }

    .successMessageWrapper.top {
        top: -9.5rem;
    }

    .successMessage {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .successMessage button {
        display:none;
    }

    .notificationWrapper > div > div {
        line-height: 100%;
    }

    .messageSpacing {
        margin-bottom: 2.5rem;

        & { 
            button {
                line-height: 1rem;
            }
        }

        & > div > div {
            width: 100%;
        }
    }
