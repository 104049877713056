@import '../../scss/vars.scss';
@import '../../scss/breakpoints.scss';
@import '@in/component-library/src/styles/variables.scss';

.loginFirstColumn {
    width: 80px;
}

.loginBold {
    font-weight: 600;
}

.pageLayout {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 0rem;
    padding-left: 16px;

    @include breakpoint-md {
        grid-template-columns: 1fr 1fr;
        padding-left: 64px;
    }
}

.front-page-nav {
    padding: 2rem 0 0 2rem;
    @include breakpoint-sm {
        padding: 2rem 0 0 9rem;
    }
    @include breakpoint-md {
        padding: 9rem 0 0 2rem;
    }
}