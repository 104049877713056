
[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
}

.icon-calendar:before {
    content: "\e901";
}
.icon-chat:before {
    content: "\e902";
}
.icon-edit:before {
    content: "\e903";
}
.icon-envelope:before {
    content: "\e904";
}
.icon-happy:before {
    content: "\e905";
}
.icon-lightbulb:before {
    content: "\e906";
}
.icon-linegraph:before {
    content: "\e907";
}
.icon-newspaper:before {
    content: "\e908";
}
.icon-pencil:before {
    content: "\e909";
}
.icon-profile-male:before {
    content: "\e90a";
}
.icon-sad:before {
    content: "\e90b";
}
.icon-search:before {
    content: "\e90c";
}
.icon-internal-rate:before {
    content: "\e900";
}
.icon-economy:before {
    content: "\e90d";
}
.icon-about:before {
    content: "\e90e";
}
.icon-production:before {
    content: "\e90f";
}
.icon-resources:before {
    content: "\e910";
}
.icon-operation-and-drift:before {
    content: "\e911";
}

.icon-lightbulb{
}

.icon-search{
    padding-left:5px;
}
