@import '../../scss/vars.scss';
@import '@in/component-library/src/styles/variables.scss';
@import '../../scss/breakpoints.scss';

.pageHeader {
    color: $in-black;
    margin-bottom: 30px;
    font-size: 30px;
}

.fieldHeader {
    color: #363636;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 25px;
}

.input:focus {
    color: $color--black;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.15s linear;
}

.input {
    color: $gray4;
    width: 100%;
    padding: 4px;
    font-size: 14px;
    max-width: 850px;
    margin-top: 5px;
    border: solid 1px $gray4;
    transition: all 0.15s linear;
    &--type-number {
        max-width: 300px;
    }
    &--type-time {
        max-width: 8rem;
    }
    &--textarea {
        min-height: 1rem;
        height: 180px;
        min-width: 500px;
        border: solid 1px $gray4;
        white-space: pre-wrap;
    }
    .input__bottom {
        min-height: 1.3rem;
        margin-top: 5px;
        position: relative;
        &--charCount {
            font-size: 0.7em;
            position: absolute;
            right: 0;
            bottom: 3px;
        }
    }
    input,
    textarea {
        border: 1px solid $gray3;
        border-radius: 2px;
    }
    input[type='number'] {
        -moz-appearance: textfield;
        max-width: 300px;
    }
    textarea[aria-invalid='false'],
    input[aria-invalid='false'] {
        border: solid 2px $success-color;
    }
    textarea[aria-invalid='true'],
    input[aria-invalid='true'] {
        border: solid 2px $alert-color;
    }
}

.errorWrapper {
    color: $alert-color;
    font-weight: 300;
    font-size: 10pt;
    width: 100%;
    margin-left: 15px;
    margin-bottom: 10px;
    &__smaller {
        height: auto;
    }
}
.errorWrapper.below {
    margin-left: 0px;
    margin-top: 5px;
}

.fieldWrapper {
    margin-bottom: 10px;
}

.formContent {
    color: black;
    display: block;
    min-width: auto;
    position: relative;
    margin: auto;
    background: white;
    padding-top: 25px;
}

.formPageInner {
    display: block;
}

.infoArea {
    display: block;
    margin-top: 5.5rem;
}

.infoArea .header {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    padding-bottom: 10px;
}

.infoAreaItem {
    display: block;
    padding: 32px;
    background-color: rgb(238, 243, 247);
    color: $in-black;
    font-family: 'InnovationNorway', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
}

// Buttons

.buttons {
    display: flex;
    height: 94px;
    width: auto;
    margin-top: 40px;
}
.buttons.single {
    display: block;
}

.button-wrapper {
    margin-right: 10px;
    vertical-align: top;
    min-width: 60px;
}

.button-wrapper.last {
    margin-right: 0px;
}

.button-wrapper.single {
    vertical-align: top;
    min-width: 60px;
    float: right;
}

.button-wrapper.single.left {
    vertical-align: top;
    min-width: 60px;
    float: left;
}

.button-border > button {
    border: 1px solid #aaa;
}

.button-wrapper.button-spacer {
    justify-content: flex-start;
    flex-grow: 1;
}

.inputGroup {
    width: auto;
}

@media only screen and (max-width: 800px) {
    .icon-left-position {
        display: none;
    }
    .icon-right-position {
        display: none;
    }
    .button-area-caption-previous {
        display: none;
    }
    .button-area-caption {
        display: none;
    }
    .button-area-text-previous {
        padding-left: 20px;
    }
    .button-area-text {
        position: inherit !important;
        padding-right: 20px;
        line-height: 21px !important;
        padding-top: 15px !important;
    }
    .save-button-wrapper {
        margin-left: 0 !important;
    }
    .cancel-wrapper {
        margin-right: auto !important;
    }
}

.columnheader {
    color: $gray4;
    width: 100%;
    padding-left: 5px;
    padding-top: 4px;
    font-size: 14px;
    white-space: nowrap;
}

.tableprefix {
    color: $gray4;
    width: 50px;
    padding-top: 8px;
    padding-bottom: 0px;
    padding-right: 4px;
    font-size: 14px;
}

.tablecell {
    color: $gray4;
    width: 100%;
    font-size: 14px;
}

.flexrow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.background {
    background: greenyellow;
}

.green {
    background: green;
}

.flexcolumn {
    width: 100%;
    flex-direction: column;
    display: flex;
}

.spacer {
    width: 200px;
    height: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    white-space: nowrap;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding-right: 15px;
}

.leadtext-column {
    flex: 1 1 0%;
    padding-top: 7px;
    display: inline-block;
}

.main-column {
    flex: 3 1;
}

.groupLabel {
    padding-bottom: 5px;
    display: block;
    color: $in-black;
}

// Helpbutton start
.help {
    height: 0px;
    opacity: 0;
    background-color: white;
    width: 370px;
    max-width: 500px;
    white-space: normal;
    padding: 0px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.help.header-helptext {
    width: 100%;
    max-width: 100%;
}

.help.input-text-helptext.popout:hover,
.help.header-helptext:hover,
.help.input-group-checkbox-helptext.popout:hover,
.help.input-group-checkbox-helptext.popout.tabbed:hover {
    background-color: white;
    color: $in-black;
}

.help.hide {
    animation: helptext-fade 0.5s ease-out;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 0px;
}

.help.popout {
    animation: helptext 0.5s ease-in;
    height: auto;
    opacity: 1;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@keyframes helptext {
    from {
        height: 100%;
        opacity: 0;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    to {
        opacity: 1;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@keyframes helptext-fade {
    from {
        height: 100%;
        opacity: 1;
        padding: 10px;
        // margin-top: 10px;
        margin-bottom: 10px;
    }
    to {
        height: 100%;
        opacity: 0;
        padding: 10px;
        // margin-top: 10px;
        margin-bottom: 10px;
    }
}

.icon {
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-left: 10px;
    color: #444;
    width: 20px;
    min-width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
}
.icon:hover,
.icon.popout {
    background-color: white;
    border: 1px solid $in-black;
    color: $in-black;
    font-weight: 600;
}
.ms-Icon {
    font-family: FabricMDL2Icons !important;
    padding-top: 1px;
}
.ms-Icon--Help:before {
    content: '?';
    color: #999;
    padding-right: 1px;
}

.ms-Icon--Help.popout:before {
    content: '?';
    color: #333;
    padding-right: 1px;
}
.ms-Icon--Help.after {
    width: 24px;
}

// Helpbutton stop

.lastMessage {
    margin-bottom: 20px;
}

.blocker {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 10;
    margin: auto;
}

.blocker.blocked {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inblocker {
    transform: scale(2);
    top: -10rem;
}

.spinner {
    width: 75px;
    height: 75px;
    margin-top: 200px;
    margin-left: 0 auto;
    margin-right: 0 auto;
}

.hiddenField {
    visibility: hidden;
    width: 0px;
    height: 0px;
}

.removeTopBorder {
    margin-top: 0px !important;
}

.freeText {
    font-size: 20px;
    font-family: 'InnovationNorway', Helvetica, Arial, sans-serif;
    font-weight: 400;
    width: 100%;
}

.infoBoxWrapper {
    margin-bottom: 20px;
}

.infoBoxWrapper > section {
    margin: 0 1rem;
}

.infoBoxRegular {
    margin: 0 1rem;
}

// override cmoponent library
.container header {
    margin-bottom: 5px !important;
    border-bottom: 0px;
}

.navLinkButton {
    margin-right: 30px;
}

.layoutGridMain {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0rem;
    row-gap: 0rem;

    @include breakpoint-md {
        grid-template-columns: 2fr 1fr;
    }
    @include breakpoint-lg {
        grid-template-columns: 2fr 1fr;
    }
}

.tableRowButton {
    margin-top: 1.5rem;
}

.layoutGridMaxElements {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 0rem;
    &.layoutGridMax2x {
        @include breakpoint-sm {
            grid-template-columns: 1fr 1fr;
        }
        @include breakpoint-md {
            grid-template-columns: 1fr 1fr;
        }
        @include breakpoint-lg {
            grid-template-columns: 1fr 1fr;
        }
    }
    &.layoutGridMax3x {
        @include breakpoint-sm {
            grid-template-columns: 1fr 1fr;
        }
        @include breakpoint-md {
            grid-template-columns: 1fr 1fr;
        }
        @include breakpoint-lg {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &.layoutGrid3x {
        @include breakpoint-sm {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-md {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-lg {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.informationHelpIcon {
    width: 99.5%;
}

// Get rid of icon pollution from other apps that are trying to mitigate user input
.formPageInner input {
    background-image: none !important;
}
